import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Swiper from 'react-id-swiper'
import Pagina from '../components/Pagina'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// CSS, SCSS
import 'swiper/css/swiper.css'
import './styles/oleo-basico.scss'

// Assets
import imgCard from '../images/oleo-basico/imgCard1.jpg'
import imgCard2 from '../images/oleo-basico/imgCard2.jpg'
import imgCard3 from '../images/oleo-basico/imgCard3.jpg'
import imgCard4 from '../images/oleo-basico/imgCard4.png'
import LWOma from '../images/oleo-basico/icons/LWOma.svg'
import LWVolt from '../images/oleo-basico/icons/LWVolt.svg'
import CarG2 from '../images/oleo-basico/icons/CarG2.svg'
import RaioVolt from '../images/oleo-basico/icons/RaioVolt.svg'
import FolhaLwoma from '../images/oleo-basico/icons/folhaLwoma.svg'
import GrauAlimenticio from '../images/oleo-basico/icons/Alimenticio.svg'
import logoAVC from '../images/oleo-basico/icons/logo-acv.svg'
import co2 from '../images/oleo-basico/icons/co2.svg'
import co2English from '../images/oleo-basico/icons/co2-english.svg'
import co2Espanhol from '../images/oleo-basico/icons/co2-espanhol.png'
import gota from '../images/oleo-basico/icons/gota.svg'

const params = {
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction'
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  spaceBetween: 30
}

const Molde = props => (
  <>
    <div className="col-lg-auto col-auto mt-3 px-2 px-lg-3">
      <div className="card card-oleoBasico justify-content-center">
        <div className={`fundoIcon position-absolute ${props.color}`} />
        <div className="position-absolute">
          {props.icon}
        </div>
        <img className="card-img w-100" src={props.image} alt="Imagem do card" />
        <div className="card-img-overlay">
          {props.marca}
          <p className="card-text line-height-normal text-lwart-gray2 font-size-08">{props.text}</p>
        </div>
      </div>
    </div>
  </>
)

const Banner = () => {
  const intl = useIntl()

  return (
    <div className='row banner-video justify-content-center position-relative p-0 m-0'>
      <div className="col-lg-6 col-12 d-flex banner-oleobasico position-relative justify-content-center">
        <div className="d-flex align-items-center justify-content-center text-lg-left text-center">
          <div className='px-lg-5 mx-lg-5 py-3 my-4'>
            <h1 className="text-white">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.banner.title' }))}</h1>
            <p className="text-white">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.banner.p1' }))}
            </p>
            <p className='text-white'>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.banner.p2' }))}</p>
            <p className='text-white'>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.banner.p3' }))}</p>
          </div>
        </div>
        <div className='d-flex d-lg-none align-self-end position-absolute logo-avc'>
          <Link to='#acv'>
            {intl.locale === 'pt'
              ? <img src={co2} />
              : <img src={co2English} />
            }
          </Link>
        </div>
      </div>
      <div className='d-lg-flex d-none align-self-center position-absolute '>
        <Link to='#acv'>
          {intl.locale === 'pt'
            ? <img src={co2} />
            : intl.locale === 'en'
              ? <img src={co2English} width='141px'/>
              : <img src={co2Espanhol} width='141px'/>
          }
        </Link>
      </div>
      <div className='d-flex col-lg-6 col-12 p-0 m-0'>
        <video autoPlay muted loop className='video-oleobasico'>
          <source src='https://d29gz1j979b5he.cloudfront.net/ampulheta+(1).mp4' type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

const OleoIdeal = () => {
  const [showMore, setShowMore] = useState()
  const intl = useIntl()

  const ShowMore = (id) => {
    setShowMore(id)
  }

  return (
    <>
      <div>
        <div className='position-relative'>
          <div className='d-flex align-items-center position-absolute oleoInfo' onMouseEnter={() => ShowMore('oleos')} onMouseLeave={() => ShowMore()}>
            <p className='text-white text-center m-0'>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.blue_circle' }))}</p>
          </div>
          {showMore === 'oleos' &&
            <div className='position-absolute text-center oleoInfoHover'>
              <div className={ intl.locale !== 'en' ? 'content' : 'content-english'}>
                <img src={gota} />
                <p className='text-white d-flex align-items-center pt-1'>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.green_circle' }))}</p>
              </div>
            </div>
          }
        </div>
        <div className="container text-center my-5 pb-lg-3 pb-0 py-lg-0 py-5 px-0">
          <div className="col-lg-12">
            <header>
              <h2 className="text-lwart-blue mb-4">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.title' }))}</h2>
            </header>
            <div className='d-flex justify-content-center'>
              <div className='col-lg-10'>
                <p>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.p1' }))}
                </p>
                <p>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.p2' }))}</p>
                <p>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.p3' }))}</p>
              </div>
            </div>
          </div>
          <div className="row p-0 m-0 mx-0 justify-content-center">
            <Link to="/g2/" className="text-decoration-none">
              <Molde image={imgCard} icon={<img src={CarG2} className="w-30" />} marca={<span className="text-lwart-blue font-Spinnaker font-size-2">G2</span>} title="G2" text={parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.molde.g2' }))} color="bg-lwart-bg-gray" />
            </Link>
            <Link to="/lwoma/" className="text-decoration-none">
              <Molde image={imgCard2} icon={<img src={FolhaLwoma} className="w-30" />} marca={<img src={LWOma} className="w-75" />} title="LW Oma" text={parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.molde.lwoma' }))} color="bg-lwart-bg-green" />
            </Link>
            <Link to="/lw-volt/" className="text-decoration-none">
              <Molde image={imgCard3} icon={<img src={RaioVolt} className="w-30" />} marca={<img src={LWVolt} className="w-75" />} title="LW | Volt" text={parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.molde.lw_volt' }))} color="bg-dark-blue" />
            </Link>
            <Link to="/grau-alimenticio/" className="text-decoration-none">
              <Molde image={imgCard4} icon={<img src={GrauAlimenticio} className="w-100" />} marca={<span className="text-lwart-blue font-Spinnaker font-size-15">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.molde.grau.span' }))}</span>} title="Grau Alimentício" text={<> <p className='card-text line-height-normal text-lwart-gray2 font-size-08 pt-2'>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.oleo_ideal.molde.grau.text' }))}</p> </>} />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

const ACV = ({ imgACV }) => {
  const intl = useIntl()

  return (
    <section id='acv' className='bg-gradient full m-0'>
      <div className='container'>
        <div className='row d-flex align-items-center pt-lg-0 pt-5'>
          <div className='col-lg-6 pr-lg-5'>
            <h1 className='text-white'>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.acv.title1' }))}
            </h1>
            <p className='pt-4 text-white font-Spinnaker line-height-normal font-size-1125'>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.acv.p1' }))}
            </p>
            <img src={logoAVC} className='py-2' />
            <h4 className='pt-4 text-white'>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.acv.title2' }))}</h4>
          </div>
          <div className='col-lg-6 pl-lg-4 py-5'>
            <GatsbyImage image={imgACV} alt='' />
          </div>
        </div>
      </div>
    </section>
  )
}

const SectionGarantia = ({ abastecimento, flexibilidade, economia }) => {
  const intl = useIntl()

  return (
    <section className="full pt-lg-5 pb-lg-5 pb-0 pt-5 m-0">
      <div className="container px-lg-5 px-3">
        <div className="row px-4 px-lg-3 m-0">
          <div className="col-lg-4 px-lg-3 pr-0 pl-0 mb-lg-0 mb-4">
            <GatsbyImage image={abastecimento} className="mb-3 custom-maxheight-altaperformance" />
            <h6 className="text-lwart-blue mb-lg-3 mb-1 mt-3">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.section_cards.garantia.title' }))}</h6>
            <p className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.section_cards.garantia.text' }))}
            </p>
          </div>
          <div className="col-lg-4 px-lg-3 pr-0 pl-0 mb-lg-0 mb-4">
            <GatsbyImage image={flexibilidade} className="mb-3 custom-maxheight-altaperformance" />
            <h6 className="text-lwart-blue mb-lg-3 mb-1 mt-3">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.section_cards.seguranca.title' }))}</h6>
            <p className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.section_cards.seguranca.text' }))}
            </p>
          </div>
          <div className="col-lg-4 px-lg-3 pr-0 pl-0 mb-lg-0 mb-4">
            <GatsbyImage image={economia} className="mb-3 custom-maxheight-altaperformance" />
            <h6 className="text-lwart-blue mb-lg-3 mb-1 mt-3">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.section_cards.desempenho.title' }))}</h6>
            <p className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.section_cards.desempenho.text' }))}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const ControleQualidade = ({ img1, img2, img3, img4 }) => {
  const intl = useIntl()

  return <>
    <section className="full m-0 bg-rigor-qualidade py-5 py-lg-0">
      <div className="container mt-lg-0 p-lg-5 px-0">
        <div className="row m-0 p-0 align-items-center px-4 px-lg-0 mx-0">
          <div className="col-lg-4 ml-0 px-0 px-lg-3">
            <h5 className="text-lwart-green mb-3">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.controle_qualidade.title1' }))}</h5>
            <h2 className="color-blue mb-3">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.controle_qualidade.title2' }))}</h2>
            <p className="line-height-1 font-size-1 mb-3">{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.controle_qualidade.p' }))}</p>
          </div>
          <div className="col-lg-8 carouselOleo px-0 px-lg-3">
            <Swiper {...params}>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={img1} />
              </div>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={img2} />
              </div>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={img3} />
              </div>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={img4} />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
    <section className="full m-0 bg-lwart-blue p-5">
      <h4 className="text-white text-center mb-0 font-mobile-custom-2"><a className="text-white" href="mailto:comercial@lwart.com.br"><u>{parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.controle_qualidade.link' }))}</u></a> {parse(intl.formatMessage({ id: 'paginas.oleos_de_alta_performance.controle_qualidade.title3' }))}</h4>
    </section>
    <div className="gradient-blue-to-green"></div>
  </>
}

const OleoBasico = ({ data }) => {
  return <>
    <Pagina pagina={data.pagina} />
    <Banner />
    <OleoIdeal />
    <ACV imgACV={data.imgACV.childImageSharp.gatsbyImageData} />
    <SectionGarantia abastecimento={data.abastecimento.childImageSharp.gatsbyImageData} flexibilidade={data.flexibilidade.childImageSharp.gatsbyImageData} economia={data.economia.childImageSharp.gatsbyImageData} />
    <ControleQualidade img1={data.img1.childImageSharp.gatsbyImageData} img2={data.img2.childImageSharp.gatsbyImageData} img3={data.img3.childImageSharp.gatsbyImageData} img4={data.img4.childImageSharp.gatsbyImageData} />
  </>
}

export default OleoBasico

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/oleos-de-alta-performance/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "oleo-basico/Banner-oleobasico.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgOleo: file(relativePath: {eq: "oleo-basico/Oleo2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgACV: file(relativePath: {eq: "oleo-basico/imgACV.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  img1: file(relativePath: {eq: "oleo-basico/img1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 670, height: 400, layout: CONSTRAINED)
    }
  }
  img2: file(relativePath: {eq: "oleo-basico/img2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 670, height: 400, layout: CONSTRAINED)
    }
  }
  img3: file(relativePath: {eq: "oleo-basico/img3.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 670, height: 400, layout: CONSTRAINED)
    }
  }
  img4: file(relativePath: {eq: "oleo-basico/img4.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 670, height: 400, layout: CONSTRAINED)
    }
  }
  abastecimento: file(relativePath: {eq: "oleo-basico/LWART_009.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  flexibilidade: file(relativePath: {eq: "oleo-basico/caminhֶo3_003.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  economia: file(relativePath: {eq: "oleo-basico/aprovado copy.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
